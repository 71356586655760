<template>
  <div class="w-full font-heading font-semibold">
    <template v-if="$page.props.auth.user.is_instructor">
      <div v-if="showInstructorDashboard" class="mb-4 ml-12 text-xs uppercase text-gray-400">
        {{ $t('Instructor') }}
      </div>
      <main-menu-item path="instructor" icon="dashboard" :href="route('dashboard.instructors')">
        {{ $t('Dashboard') }}
      </main-menu-item>
      <main-menu-item path="instructor/classes*" icon="apple" :href="route('instructor.classes')">
        {{ $t('Classes') }}
      </main-menu-item>
      <main-menu-item path="instructor/availability*" icon="calendar" :href="route('instructor.availability')">
        {{ $t('Availability') }}
      </main-menu-item>
      <div v-if="showInstructorDashboard" class="mb-4 mt-6 ml-12 text-xs uppercase text-gray-400">
        {{ $t('Admin') }}
      </div>
    </template>

    <template
      v-if="$page.props.auth.user.is_admin || $page.props.auth.user.is_support || $page.props.auth.user.is_super"
    >
      <main-menu-item path="" icon="dashboard" :href="route('dashboard')">
        {{ $t('Dashboard') }}
      </main-menu-item>
      <main-menu-item path="organizations*" icon="office" :href="route('organizations')">
        {{ $t('Organizations') }}
      </main-menu-item>
      <main-menu-item path="users*" icon="users" :href="route('users')">
        {{ $t('Users') }}
      </main-menu-item>
      <main-menu-item path="enrolments*" icon="collection" :href="route('enrolments')">
        {{ $t('Enrolments') }}
      </main-menu-item>
      <main-menu-item
        :href="route('courses')"
        icon="book"
        :path="[
          'courses*',
          'course-location',
          'course-categories*',
          'instructor-led-classrooms*',
          'checkin-forms*',
          'quizzes*',
          'elearnings*',
          'files*',
          'links*',
          'certificate-templates*',
        ]"
      >
        {{ $t('Courses') }}
        <template #submenu>
          <main-menu-item path="courses*" :href="route('courses')" :is-sub-menu="true">
            {{ $t('Courses') }}
          </main-menu-item>
          <main-menu-item path="elearnings*" :href="route('elearnings')" :is-sub-menu="true">
            {{ $t('eLearning') }}
          </main-menu-item>
          <main-menu-item
            path="instructor-led-classrooms*"
            :href="route('instructor-led-classrooms')"
            :is-sub-menu="true"
          >
            {{ $t('Classes') }}
          </main-menu-item>
          <main-menu-item path="checkin-forms*" :href="route('checkin-forms')" :is-sub-menu="true">
            {{ $t('Check-In Form') }}
          </main-menu-item>
          <main-menu-item path="files*" :href="route('files')" :is-sub-menu="true">
            {{ $t('Files') }}
          </main-menu-item>
          <main-menu-item path="links*" :href="route('links')" :is-sub-menu="true">
            {{ $t('Links') }}
          </main-menu-item>
          <main-menu-item path="quizzes*" :href="route('quizzes')" :is-sub-menu="true">
            {{ $t('Quizzes') }}
          </main-menu-item>
          <main-menu-item path="certificate-templates*" :href="route('certificate-templates')" :is-sub-menu="true">
            {{ $t('Certificates') }}
          </main-menu-item>
        </template>
      </main-menu-item>
      <main-menu-item icon="calendar" path="classes*" :href="route('classes')">
        {{ $t('Classes') }}
      </main-menu-item>
      <main-menu-item icon="location" :path="['locations*', 'rooms']" :href="route('locations')">
        {{ $t('Locations') }}
      </main-menu-item>
      <main-menu-item
        icon="document"
        :path="['transactions*', 'subscription-plans*', 'resellers*']"
        :href="route('transactions')"
      >
        {{ $t('eCommerce') }}
        <template #submenu>
          <main-menu-item path="transactions*" :href="route('transactions')" :is-sub-menu="true">
            {{ $t('Transactions') }}
          </main-menu-item>
          <main-menu-item path="subscription-plans*" :href="route('subscription-plans')" :is-sub-menu="true">
            {{ $t('Subscription Plans') }}
          </main-menu-item>
          <main-menu-item
            v-if="$page.props.auth.user.is_tenant_owner || $page.props.auth.user.is_super"
            path="resellers*"
            :href="route('resellers')"
            :is-sub-menu="true"
          >
            {{ $t('Resellers') }}
          </main-menu-item>
        </template>
      </main-menu-item>
      <main-menu-item icon="clipboard" :path="['form-templates*']" :href="route('form-templates')">
        {{ $t('EHS') }}
        <template #submenu>
          <main-menu-item path="form-templates*" :href="route('form-templates')" :is-sub-menu="true">
            {{ $t('Form Templates') }}
          </main-menu-item>
        </template>
      </main-menu-item>
      <main-menu-item
        v-if="$page.props.auth.user.is_super"
        icon="clipboard"
        :path="['triggers*', 'subscription-banners*']"
        :href="route('triggers')"
      >
        {{ $t('Marketing') }}
        <template #submenu>
          <main-menu-item path="triggers*" :href="route('triggers')" :is-sub-menu="true">
            {{ $t('Automation Triggers') }}
          </main-menu-item>
          <main-menu-item path="subscription-banners*" :href="route('subscription-banners')" :is-sub-menu="true">
            {{ $t('Subscription Banners') }}
          </main-menu-item>
        </template>
      </main-menu-item>
      <main-menu-item icon="clipboard" path="plastic-card-orders*" :href="route('plastic-card-orders')">
        {{ $t('Plastic Cards') }}
      </main-menu-item>
      <main-menu-item icon="printer" path="reports*" :href="route('reports')">
        {{ $t('Reports') }}
      </main-menu-item>
    </template>
  </div>
</template>

<script>
import { isUrl } from '@/Utils/Helpers'
import MainMenuItem from './MainMenuItem.vue'

export default {
  components: { MainMenuItem },
  data() {
    return {
      showContents: false,
      showCourseDropdown: false,
    }
  },
  computed: {
    showInstructorDashboard() {
      return (
        this.$page.props.auth.user.is_instructor &&
        (this.$page.props.auth.user.is_admin ||
          this.$page.props.auth.user.is_support ||
          this.$page.props.auth.user.is_super)
      )
    },
    prefix() {
      return 'admin/'
    },
  },
  methods: {
    isUrl,
  },
}
</script>
