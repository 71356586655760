<template>
  <label class="flex items-center justify-center border" :class="{ 'border-blue-600': isFocussed }">
    <div class="mr-3">
      <input
        class="height-0 width-0 pointer absolute opacity-0"
        type="radio"
        :name="name"
        :checked="isChecked"
        :value="value"
        @focus="isFocussed = true"
        @blur="isFocussed = false"
        @input="onChange"
      />
      <Icon v-if="isChecked" name="checkmark" class="h-6 w-6 flex-1 rounded-full bg-ts-gray-text-bold fill-white" />
      <div v-else class="h-6 w-6 flex-1 rounded-full border-2 bg-white" />
    </div>
    <div class="flex-1">
      <span>{{ label }}</span>
      <span v-if="correct">(Correct)</span>
    </div>
  </label>
</template>

<script>
export default {
  props: {
    name: { type: String, default: '', required: true },
    label: { type: String, default: '', required: true },
    correct: { type: Boolean },
    modelValue: {
      type: [String, Boolean],
      default: '',
    },
    value: {
      type: [String, Object, Number],
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isFocussed: false,
    }
  },
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value[0])
      }
      return this.modelValue === this.value
    },
  },
  methods: {
    onChange() {
      this.$emit('update:modelValue', this.value)
    },
  },
}
</script>
