<template>
  <div class="font-heading font-medium">
    <div class="mb-4">
      <menu-item :name="$t('Organization')" class="text-ts-gray-bold mb-2 font-semibold" />
      <menu-item
        :name="$t('Settings')"
        icon="user"
        :route-link="route('front.admin.settings')"
        url="account/admin/settings*"
      >
        <icon name="setting" class="mr-2 h-6 w-6" style="margin-left: -0.15rem" />
      </menu-item>
      <menu-item :name="$t('Purchases')" :route-link="route('front.admin.purchases')" url="account/admin/purchases*">
        <icon name="purchase" class="mr-3 h-5 w-5" fill="currentColor" />
      </menu-item>
      <menu-item
        :name="$t('Subscriptions')"
        :route-link="route('front.admin.subscriptions')"
        url="account/admin/subscriptions*"
      >
        <icon name="view-list" class="mr-3 h-5 w-5" fill="currentColor" />
      </menu-item>
    </div>
    <div class="mb-4">
      <div class="mb-4">
        <menu-item :name="$t('Training')" class="text-ts-gray-bold mb-2 font-semibold" />
        <menu-item
          :name="$t('Training Records')"
          :route-link="route('front.admin.training-record-templates')"
          url="account/admin/training-record-templates*"
        >
          <icon name="book" class="mr-2 h-6 w-6" style="margin-left: -0.15rem" />
        </menu-item>
        <menu-item
          name="Access Codes"
          :route-link="route('front.admin.access-codes')"
          url="account/admin/access-codes*"
        >
          <icon name="key" class="mr-2 h-6 w-6" style="margin-left: -0.15rem" />
        </menu-item>
      </div>
      <div v-if="$page.props.auth.user.organization && hasEhsAccess" class="mb-4">
        <menu-item :name="$t('EHS')" class="text-ts-gray-bold mb-2 font-semibold" />
        <menu-item
          :name="$t('Form Templates')"
          :route-link="route('front.admin.form-templates')"
          url="account/admin/form-templates*"
        >
          <icon name="pencil" class="mr-2 h-6 w-6" style="margin-left: -0.15rem" />
        </menu-item>
        <menu-item name="Manuals" :route-link="route('front.admin.manuals')" url="account/admin/manuals*">
          <icon name="stack" class="mr-2 h-6 w-6" style="margin-left: -0.15rem" />
        </menu-item>
        <menu-item name="Files" :route-link="route('front.admin.folders')" url="account/admin/folders*">
          <icon name="document" class="mr-2 h-6 w-6" style="margin-left: -0.15rem" />
        </menu-item>
        <menu-item
          name="Job Profiles"
          :route-link="route('front.admin.job-profiles')"
          url="account/admin/job-profiles*"
        >
          <icon name="users" class="mr-2 h-6 w-6" style="margin-left: -0.15rem" />
        </menu-item>
      </div>
      <div>
        <menu-item
          v-if="$page.props.app.tenant.phone && $page.props.app.tenant.email"
          :name="$t('Support')"
          class="text-ts-gray-bold mb-2 font-semibold"
        />
        <front-phone-menu-item />
        <front-email-menu-item />
      </div>
      <front-divider-menu-item />
      <div>
        <front-back-office-admin-menu-item />
        <front-logout-menu-item />
      </div>
    </div>
  </div>
</template>

<script>
import { isUrl } from '@/Utils/Helpers'

export default {
  computed: {
    hasEhsAccess() {
      return this.$page.props.auth.user.organization?.has_subscription && this.$page.props.auth.user.has_ehs_access
    },
  },
  methods: {
    isUrl,
  },
}
</script>
